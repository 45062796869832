import { LoaderFunctionArgs } from '@remix-run/node';
import { Form, json, Outlet, useLoaderData } from '@remix-run/react';
import { authenticator, sessionStorage } from '~/auth/authenticator';
import loginBgImage from '~/assets/login-bg.svg';

export async function loader({ request }: LoaderFunctionArgs) {
	await authenticator.isAuthenticated(request, {
		successRedirect: '/dash',
	});
	const session = await sessionStorage.getSession(request.headers.get('Cookie'));
	const authError = session.get(authenticator.sessionErrorKey);

	// Commit session to clear any `flash` error message.
	return json(
		{ authError },
		{
			headers: {
				'set-cookie': await sessionStorage.commitSession(session),
			},
		},
	);
}

export default function LoginPage() {
	const { authError } = useLoaderData<typeof loader>();
	return (
		<div className="flex w-full h-screen relative">
			<div
				className="w-1/2 h-screen bg-black border-r border-r-gray-700 rounded-r-xl relative flex items-center justify-center"
				style={{
					boxShadow: '12px 0px 120px 0px rgba(0, 0, 0, 0.24)',
					zIndex: 1,
				}}
			>
				<Outlet />
			</div>
			<div
				className="w-1/2 h-screen flex items-center justify-center"
				style={{
					backgroundImage: `url(${loginBgImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				<div className="p-10 border border-gray-500 rounded-lg bg-gray-950 min-w-[386px] flex flex-col justify-center">
					<p>This is going to be a quote</p>
				</div>
			</div>

			{/* <Form method="post" action="/auth/totp/generate_code">
				<label htmlFor="email">Email</label>
				<input type="email" name="email" placeholder="Insert email .." required />
				<button type="submit">Send Code</button>
				<button type="submit">Login</button>
			</Form>
			{authError && <pre>{JSON.stringify(authError, null, 2)}</pre>} */}
		</div>
	);
}
